<template>
  <v-dialog :value="value" persistent width="600">
    <v-card color="fill">
      <v-card-title :class="title">
        {{ isExistingAd ? selectedAd.ad_name : 'Create Ad' }}
        <v-spacer />
        <v-menu offset-y offset-x left bottom :nudge-right="5" :nudge-top="5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="readonly" v-bind="attrs" v-on="on" icon v-if="isExistingAd" color="delete"
              class="white--text">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-6">
            <v-row justify="center" class="py-4">
              <span>Delete Ad?</span>
            </v-row>
            <v-row>
              <v-btn text :class="body">Cancel</v-btn>
              <v-btn :class="body" class="primary white--text ml-2" @click="deleteAd">Confirm</v-btn>
            </v-row>
          </v-card>
        </v-menu>
      </v-card-title>
      <v-container class="ma-0 px-6 pt-6" fluid>
        <v-row class='pa-2'>
          <v-breadcrumbs v-if='clonedAd' class='primary--text'>
            <v-breadcrumbs-item class='' style='cursor:pointer' @click='viewClone'>
              Cloned from {{ clonedFrom.ad_name }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-row>
        <v-form ref="form" v-model="valid" :readonly="readonly">
          <v-row v-if="!isExistingAd" justify="center" align="center" dense>
            <v-col>
              <v-autocomplete :class="body" autofocus auto-select-first v-model="layout" :items="layouts"
                item-text="ad_name" item-value="id" background-color="input" label="Ad Layout (Optional)"
                autocomplete="off" outlined dense :menu-props="{ maxHeight: 220, rounded: '' }" clearable>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.ad_name }}</v-list-item-title>
                      <!-- <v-list-item-subtitle class="text-capitalize">{{data.item.party_type.name}}</v-list-item-subtitle> -->
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider v-if="!isExistingAd" class="py-4" />
          <v-row dense>
            <v-col>
              <v-autocomplete :class="body" :rules="[v => !!v || `${partyLabel} Required`]" auto-select-first
                v-model="ad.ad_party_id" :items="parties" item-text="party_name" item-value="party_id"
                background-color="input" :label="partyLabel" autocomplete="off" outlined dense
                :menu-props="{ maxHeight: 220, rounded: '' }" :clearable="!isExistingAd" :readonly="isExistingAd">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      <!-- <v-list-item-subtitle class="text-capitalize">{{data.item.party_type.name}}</v-list-item-subtitle> -->
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select v-if="!isExistingAd" :class="body" autofocus v-model="ad.ad_type_id" :items="adTypes"
                item-text="name" item-value="id" background-color="input" label="Ad Type"
                :rules="[v => !!v || 'Ad Type Required']" outlined dense clearable>
              </v-select>
              <v-select v-else-if="isExistingAd" :class="body" autofocus v-model="ad.ad_type" :items="adTypes"
                item-text="name" item-value='id' background-color="input" label="Ad Type"
                :rules="[v => !!v || 'Ad Type Required']" outlined dense clearable>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field :class="body" :rules="adNameRules" v-model="ad.ad_name" background-color="input"
                label="Ad Name" autocomplete="off" dense outlined clearable />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" bottom nudge-top="25" :nudge-left="10"
                transition="scale-y-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :class="body" :value="formattedStart" outlined dense background-color="input"
                    label="Start Date" prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Start Date Required']"
                    readonly v-bind="attrs" v-on="on" clearable @click:clear="resetStartDate" />
                </template>
                <v-date-picker :show-current="false" :color="readonly ? 'grey lighten-2' : 'accent'" :disabled="readonly"
                  v-model="ad.ad_start_date" @input="menu1 = false" no-title :max="ad.ad_end_date" />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" bottom nudge-top="25" :nudge-left="10"
                transition="scale-y-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :class="body" :value="formattedEnd" outlined dense background-color="input"
                    label="End Date" prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'End Date Required']"
                    readonly v-bind="attrs" v-on="on" clearable @click:clear="resetEndDate" />
                </template>
                <v-date-picker :show-current="false" :color="readonly ? 'grey lighten-2' : 'accent'" :disabled="readonly"
                  v-model="ad.ad_end_date" @input="menu2 = false" no-title :min="ad.ad_start_date" />
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" bottom nudge-top="25" :nudge-left="10"
                transition="scale-y-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :class="body" :value="formattedFirstShip" outlined dense background-color="input"
                    label="1st Ship Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="resetFirstShipDate" />
                </template>
                <v-date-picker :show-current="false" :color="readonly ? 'grey lighten-2' : 'accent'" :disabled="readonly"
                  v-model="first_ship_date" @input="menu3 = false" no-title :max="second_ship_date" />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" bottom nudge-top="25" :nudge-left="10"
                transition="scale-y-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :class="body" :value="formattedSecondShip" outlined dense background-color="input"
                    label="2nd Ship Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="resetSecondShipDate" />
                </template>
                <v-date-picker :show-current="false" :color="readonly ? 'grey lighten-2' : 'accent'" :disabled="readonly"
                  v-model="second_ship_date" @input="menu4 = false" no-title :min="first_ship_date" />
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea :class="body" outlined dense background-color="input" label="Notes (Optional)" no-resize hide-details
                rows="3" v-model="ad.notes" clearable />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-toolbar flat color="fill">
        <span v-if="readonly && isExistingAd" class="text-h6 font-weight-light">Read-only View</span>
        <v-spacer />
        <v-btn text :class="body" @click="$emit('update:value', false)">
          {{ isExistingAd ? 'Close' : 'Cancel' }}
        </v-btn>
        <v-btn 
          color="save" 
          :class="body" 
          :disabled="creatingAd"
          class="white--text ml-2"
          @click="creatingAd = true; isExistingAd ? updateAd() : createAd()">
          {{ isExistingAd ? 'Save Update' : 'Save' }}
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { notification } from '@/mixins/notification'
import { userAccess } from '@/mixins/user-access'
export default {
  name: 'AdView',
  mixins: [displayHelpers, notification, userAccess],
  props: [
    'value',
    'selectedAd',
    'layouts',
    'defaultParty'
  ],
  data() {
    return {
      adNameRules: [
        value => !!value || 'Name is Required',
        value => (value || '').length <= 512 || '512 characters maximum',
      ],
      min_date: null,
      ad: {
        ad_name: '',
        ad_party_id: '',
        ad_start_date: '',
        ad_end_date: '',
        classification: '',
        ad_type_id: '',
        ad_type: {},

      },
      first_ship_date: '',
      second_ship_date: '',
      clonedFrom: {},
      requiredMessage: 'Field Required',
      confirmDialog: false,
      filters: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      type: 'Ad',
      adTypes: [],
      tempType: {},
      layout: {},
      valid: true,
      newParty: {},
      creatingAd: false
    }
  },
  computed: {
    parties() {
      if(this.useAdGroups) {
        if (this.limitAccessByRelatedAdGroups) {
          return this.$store.getters.adGroups.filter(adGroup => this.userAdGroupIds.includes(adGroup?.party_id))
        } else {
          return this.$store.getters.adGroups
        }
      } else {
        return this.$store.getters.userStores
      }
    },
    partyLabel() {
      return this.useAdGroups ? 'Ad Group' : 'Store'
    },
    readonly() {
      return this.isExistingAd && this.selectedAd.status !== 'Draft' && this.selectedAd.status !== 'Rejected' && this.selectedAd.status !== 'Error'
    },
    layoutSelected() {
      return !this._.isEmpty(this.layout) && !this.isExistingAd
    },
    noLayout() {
      return this.layout === 'No Layout'
    },
    allFields() {
      const layout = !this._.isEmpty(this.layout)
      const ad_name = !this._.isEmpty(this.ad.ad_name)
      const ad_party_id = !this._.isEmpty(this.ad.ad_party_id)
      const ad_start_date = !this._.isEmpty(this.ad.ad_start_date)
      const ad_end_date = !this._.isEmpty(this.ad.ad_end_date)

      return layout && ad_name && ad_party_id && ad_start_date && ad_end_date
    },
    isExistingAd() {
      return !this._.isEmpty(this.selectedAd)
    },
    formattedStart() {
      return this.ad.ad_start_date ? this.moment(this.ad.ad_start_date).format(this.$dateConfig.date_display) : ''
    },
    formattedEnd() {
      return this.ad.ad_end_date ? this.moment(this.ad.ad_end_date).format(this.$dateConfig.date_display) : ''
    },
    formattedFirstShip() {
      return this.first_ship_date ? this.moment(this.first_ship_date).format(this.$dateConfig.date_display) : ''
    },
    formattedSecondShip() {
      return this.second_ship_date ? this.moment(this.second_ship_date).format(this.$dateConfig.date_display) : ''
    },
    clonedAd() {
      return this.ad.cloned_from_ad_id
    },
    classification() {
      return this.$store.getters.classifications.find(c => c.constant === 'AD')
    }
  },
  watch: {
    selectedAd: {
      immediate: true,
      handler() {
        if (!this._.isEmpty(this.selectedAd)) {
          this.ad = this._.cloneDeep(this.selectedAd)
        }
      }
    },

    // ad: {
    //   deep: true,
    //   handler: debounce(function (newValue, oldValue) {
    //     if (this.isExistingAd && !this._.isEmpty(oldValue)) {
    //       this.updateAd()
    //     }
    //   }, 500)
    // },
  },
  async created() {
    if (this.clonedAd) {
      this.getClonedAd()
    }
    await this.getAdTypes()
    this.ad.ad_party_id = this.defaultParty.id
  },
  methods: {
    resetFirstShipDate() {
      this.first_ship_date = ''
    },
    resetSecondShipDate() {
      this.second_ship_date = ''
    },
    resetStartDate() {
      this.ad.ad_start_date = ''
    },
    resetEndDate() {
      this.ad.ad_end_date = ''
    },
    allowedStartDates: val => ![1, 2, 3, 4, 5, 6].includes(new Date(val).getDay()),
    allowedEndDates: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),
    async updateAd() {
      const valid = this.$refs.form.validate()
      if (valid) {
        if (this.ad.ad_type.id) {
          this.ad.ad_type = this.ad.ad_type.id
        }
        await this.$Ads.updateAd(this.ad.id, this.ad)
          .then(() => {
            this.$emit('refresh')
            this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.ad.ad_name} Updated` })
          }).catch(err => {
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update due to ${err?.response?.data?.message || err.message}` })
          })
      }
      this.creatingAd = false
    },
    grabAdType(ad_type_id) {
      return this.adTypes.find(({ id }) => id === ad_type_id);
    },
    async createAd() {
      const valid = this.$refs.form.validate()
      this.ad.classification = this.classification.id
      if (valid) {
        this.ad.ad_type = this.grabAdType(this.ad.ad_type_id)
        if (!this._.isEmpty(this.layout)) {
          await this.$Layouts.createAdFromLayout(this.layout, this.ad)
            .then(() => {
              this.$emit('refresh')
              this.$emit('update:value', false)
              this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.ad.ad_name} Created` })
            }).catch(err => {
              this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Create Ad due to ${err?.response?.data?.message || err.message}` })
            }).finally()
        } else {
          if (this.first_ship_date) {
            this.ad.first_ship_date = this.first_ship_date
          }
          if (this.second_ship_date) {
            this.ad.second_ship_date = this.second_ship_date
          }
          await this.$Ads.createAd(this.ad)
            .then(res => {
              this.$emit('refresh')
              this.ad.id = res.data.id
              this.$emit('update:value', false)
              this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.ad.ad_name} Created` })
              this.ad.status = 'Draft'
              this.$router.push({ name: 'Ad Features', params: { selectedAd: this.ad, party: this.defaultParty }, query: { id: this.ad.id } })
            }).catch(err => {
              this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Create Ad due to ${err?.response?.data?.message || err.message}` })
            }).finally()
        }
      } else {
        this.creatingAd = false
      }
    },
    async deleteAd() {
      try {
        await this.$Ads.deleteAd(this.selectedAd.id)
        this.$emit('refresh')
        this.$emit('update:value', false)
        this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.ad.ad_name} Deleted` })
      }
      catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Delete Ad due to ${err?.response?.data?.message || err.message}` })
      }
    },
    async getAdTypes() {
      await this.$KeyValues.getAdTypes().then(res => {
        if (this.$auth.tenant === 'pricechopper') {
          this.adTypes = res.data.filter(adType => adType.constant !== 'PERISHABLE')
        } else {
          this.adTypes = res.data
        }
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `${err?.response?.data?.message || err.message}` })
      })
    },
    async getClonedAd() {
      await this.$Ads.getAdById(this.selectedAd.cloned_from_ad_id).then(res => {
        this.clonedFrom = res.data
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `${err?.response?.data?.message || err.message}` })
      })
    },
    addFilter() {
      this.filters.push({
        'choice': '',
        'types': [
          'Wholesaler',
          'Ad Group',
          'Date'
        ],
        'value': ''
      })
    },
    deleteFilter(index) {
      this.filters.splice(index, 1)
    },
    viewClone() {
      this.$router.push({ name: 'Ad Features', params: { selectedAd: this.clonedFrom, party: this.defaultParty }, query: { id: this.clonedFrom.id } })
    }
  }
}
</script>
